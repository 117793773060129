import React, { useEffect, useState } from "react";
import ChatBubble from "./ChatBubble";
import { Message } from "./Message";
import { useChat } from "./ChatContext";
import { ChatContextType } from "./ChatContextType";
import ChatInput from "./ChatInput";

export default function ChatWrapper() {
  const [expanded, setExpanded] = useState<boolean>(false);
  const [chatRooms] = useState<string[]>(["global", "lobby"]);
  const [messagesInSelectedRoom, setMessages] = useState<Message[]>([]);
  const { messages, scope, setScope } = useChat();

  // When the chat is changed, load the correct messages into the message-state of this component.
  useEffect(() => {
    setMessages(messages[scope as keyof ChatContextType]!);
  }, [scope]);

  useEffect(() => {
    setMessages(messages[scope as keyof ChatContextType]!);
  });

  // Scroll chat down when a new Message arrives.
  useEffect(() => {
    if (getMessagesElement()) {
      getMessagesElement()!.scrollTo(0, getMessagesElement()!.scrollHeight);
    }
  }, [messages]);

  function getMessagesElement(): HTMLDivElement {
    return document.getElementById("messages") as HTMLDivElement;
  }

  if (expanded) {
    return (
      <div className="position-absolute bottom-0 end-0 glass-menu chat-open">
        <ul className="nav nav-tabs mt-1 ms-2">
          {chatRooms.map((chatRoom) => {
            return (
              <li className="nav-item text-center" key={chatRoom}>
                <div
                  className={
                    scope === chatRoom
                      ? "selected-chat active"
                      : "unselected-chat"
                  }
                  onClick={() => setScope(chatRoom)}
                  key={chatRoom}
                >
                  {chatRoom}
                </div>
              </li>
            );
          })}
        </ul>
        <i
          className="bi bi-x align-content-center position-absolute top-0 end-0 me-2"
          onClick={() => setExpanded(false)}
          style={{ cursor: "pointer", fontSize: "1.5em" }}
        />
        <div
          className={"d-flex flex-column"}
          style={{ maxHeight: "20em", overflowY: "scroll" }}
          id={"messages"}
        >
          {messagesInSelectedRoom.map((message) => {
            return ChatBubble(message);
          })}
        </div>
        <ChatInput />
      </div>
    );
  } else {
    return (
      <div
        onClick={() => setExpanded(true)}
        className="position-absolute bottom-0 end-0 glass-menu chat-closed"
      >
        <i className="bi-chat-left-fill align-middle mx-2" />
        Chat
      </div>
    );
  }
}
