import { AiPathfinding, location } from "./AiPathfinding";
import { PlayerType } from "../PlayerContext";
import { FieldWrapper } from "../FieldWrapper";
import { BombType } from "../BombsRenderer";

export class AiAgent {
  pending_actions: location[] = [];
  node: AiPathfinding;
  playerIndex: number;

  constructor(player: PlayerType) {
    this.pending_actions = [];
    this.node = new AiPathfinding(
      { x: player.col, y: player.row },
      undefined,
      undefined
    );
    this.playerIndex = player.id;
  }

  nextMove(
    game_state: FieldWrapper[],
    player_state: location,
    bombs: BombType[]
  ): location {
    let action;
    if (this.pending_actions.length > 0) {
      action = this.pending_actions.pop();
    } else {
      let target = this.getNearestTarget() as location;
      let path = this.node.calculateOptimalAiPath(
        game_state,
        player_state,
        target,
        bombs
      );
      if (path) {
        this.pending_actions = this.node.get_path_actions(path);
        action = this.pending_actions.pop();
      }
    }

    return action ?? { x: 0, y: 0 };
  }

  clearPendingActions() {
    this.pending_actions = [];
  }

  getNearestTarget() {
    let distance: number[] = [];
    let position: location[] = [];
    //@ts-ignore
    const ownPlayer = global.players.get(this.playerIndex);
    if (ownPlayer !== undefined) {
      //@ts-ignore
      global.players.forEach((player) => {
        if (player.id !== this.playerIndex && player.alive) {
          let value =
            Math.sqrt(Math.pow(player.col - ownPlayer.col, 2)) +
            Math.pow(player.row - ownPlayer.row, 2);
          distance.push(value);
          position.push({ x: player.col, y: player.row });
        }
      });

      let index = distance.indexOf(Math.min(...distance));
      return position[index];
    }
  }
}
