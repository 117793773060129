import { BackgroundImage } from "../common/BackgroundImage";
import { Button } from "../common/Button";
import { socket } from "../App";
import { useEffect, useState } from "react";
import { useUser } from "../account/UserContext";
import { useNavigate } from "react-router-dom";

export default function Lobby() {
  const [s] = useState(socket);
  const { user, setUser } = useUser();
  const [ownGroupId, setOwnGroupId] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    socket.emit("createGroup");
  }, []);

  // Socket-Listener need to be in an useEffect. Otherwise, they are triggered multiple times.
  useEffect(() => {
    s.on("groupCreated", (groupId) => {
      setOwnGroupId(groupId);
    });

    s.on("gameStarted", () => {
      navigate("/multiplayer/online");
    });
  }, [s]);

  // ToDo: Error handling when group is already full.
  function setGroup(groupId: string) {
    // Leave the current group on change.
    socket.emit("leaveGroup", user.groupId);

    // In case the user removes the groupId in the input field. Then the default (= own created GroupId) should be used again.
    if (groupId === "") {
      socket.emit("joinGroup", ownGroupId);
    }

    setUser({ ...user, groupId: groupId });
    socket.emit("joinGroup", groupId);
  }

  function startGame() {
    socket.emit("startGame");
    navigate("/multiplayer/online");
  }

  return (
    <BackgroundImage image={"nathan-watson"}>
      <div
        className={
          "position-absolute top-50 start-50 translate-middle glass-menu m-2"
        }
      >
        <div className={"m-4"}>
          <h1 data-testid={"lobby-title"}>Multiplayer Lobby</h1>
          <div>
            <label htmlFor={"groupIdInput"} className={"d-block"}>
              Enter the GroupID of your friend:{" "}
            </label>
            <input
              id={"groupIdInput"}
              onChange={(event) => setGroup(event.target.value)}
            />
          </div>
          <p>
            or tell them yours: <b>{ownGroupId}</b>
          </p>
          <Button onClick={startGame} text={"Start Game"} />
        </div>
      </div>
    </BackgroundImage>
  );
}
