import React, { forwardRef, useEffect } from "react";
import { FIELDS } from "./Fields";
import { useFields } from "./FieldsContext";

function FieldGridRenderer() {
  const { defaultMap, resetFields } = useFields();

  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  let spriteSheet = new Image();
  const blockSize = 64;

  let context: CanvasRenderingContext2D | null;

  // Get the initialized canvas context.
  useEffect(() => {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;
    spriteSheet.onload = () => {
      for (let field of defaultMap) {
        drawField(field.fieldtype, field.x, field.y);
      }
    };
    spriteSheet.src = "/art/default/spriteSheet.png";
  });

  function drawField(type: FIELDS, x: number, y: number) {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;
    let spriteX: number = 0;
    let spriteY: number = 0;

    switch (type) {
      case FIELDS.NORMAL:
        spriteX = 0;
        spriteY = 528;
        break;
      case FIELDS.DESTROYABLE:
        spriteX = 128;
        spriteY = 528;
        break;
      case FIELDS.UNDESTROYABLE:
        spriteX = 64;
        spriteY = 592;
    }

    context?.drawImage(
      spriteSheet,
      spriteX,
      spriteY,
      blockSize,
      blockSize,
      x * blockSize,
      y * blockSize,
      blockSize,
      blockSize
    );
  }

  return (
    <canvas
      height={"832px"}
      width={"960px"}
      ref={canvasRef}
      data-testid={"fields-renderer"}
    />
  );
}

export default forwardRef(FieldGridRenderer);
