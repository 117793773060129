import { createContext, useContext, useEffect, useState } from "react";
import { SONGS } from "./Songs";

type SongsType = `${SONGS}`;

type MusicContextHookType = {
  currentSong: SongsType;
  setCurrentSong: Function;
  isPlaying: boolean;
  setIsPlaying: Function;
};

export const MusicContext = createContext<MusicContextHookType>({
  currentSong: SONGS.GORILLAZ__FEEL_GOOD_INC,
  setCurrentSong: () => {},
  isPlaying: true,
  setIsPlaying: () => {},
});

// @ts-ignore
export default function MusicProvider({ children }) {
  const [currentSong, setCurrentSong] = useState<SongsType>(
    SONGS.RAMMSTEIN__DEUTSCHLAND
  );

  const [isPlaying, setIsPlaying] = useState<boolean>(true);

  useEffect(() => {
    let localStoragePreference = localStorage.getItem("playMusic");
    // Only play the music when the preference is set to true or the player visits the site for the first time (that's wenn it's null).
    let shouldPlayMusic =
      localStoragePreference === "true" || localStoragePreference === null;
    setIsPlaying(shouldPlayMusic);
  }, []);

  useEffect(() => {
    localStorage.setItem("playMusic", isPlaying.toString());
  }, [isPlaying]);

  return (
    <MusicContext.Provider
      value={{ currentSong, setCurrentSong, isPlaying, setIsPlaying }}
    >
      {children}
    </MusicContext.Provider>
  );
}

export function useMusic() {
  return useContext(MusicContext);
}
