import React, {useEffect, useRef} from "react";
import { PlayerType, usePlayers } from "./PlayerContext";
import { PowerUp, usePowerUps } from "./PowerUpContext";
import { BombType, explosionOnField, PowerUpTypes } from "./BombsRenderer";
import { useBombs } from "./BombsContext";
import { FIELDS } from "./Fields";
import { useFields } from "./FieldsContext";
import { directions } from "./Game";
import { playSound } from "../utils/playSound";
import { SOUND_EFFECTS } from "../sounds/SoundEffects";
import { useUser } from "../account/UserContext";
import { socket } from "../App";

// Because apparently, React does not always the latest state value (of players). This leads to the problem of
// players jumping around, especially when the remote user moves. To circumvent this, it was taken advantage of the
// global object (a functionality by Node). For logic and communication with remote players, this global object is
// used as the source of truth. The state is updated afterwards so that the render still can take place using the established
// context-hooks.
// @ts-ignore
global.players = new Map();

export default function useGameFacade() {
  const { players, setPlayers, setPreviousPlayers } = usePlayers();
  const {
    powerUps,
    setPowerUps,
    powerUpsToDraw,
    setPowerUpsToDraw,
    powerUpsToClear,
    setPowerUpsToClear,
  } = usePowerUps();
  const { bombs, bombsToDraw, setBombsToDraw } = useBombs();
  const { defaultMap } = useFields();
  const { user } = useUser();
  const powerUpsRef = useRef(powerUps);
  powerUpsRef.current = powerUps;


  function movePlayer(id: number, col: number, row: number) {
    // Only proceed if the move is valid.
    if (moveIsValid(id, col, row)) {
      moveWithoutValidation(id, col, row);
    }
  }

  function setAllPreviousPlayers(){
    //@ts-ignore
    setPreviousPlayers(new Map(global.players));
  }

  // Initialize the global Players as well.
  useEffect(() => {
    // @ts-ignore
    global.players = new Map(players);
  }, []);

  // Separate function, since API-Events should be executed without additional validation.
  function moveWithoutValidation(id: number, col: number, row: number) {
    let player = players.get(id)!;
    let allPlayers = players;

    // Check whether the player moves onto a field with a powerUp. If yes, the powerUp is returned.
    const [isOnPowerUp, powerUp] = isOnFieldWithPowerUp(col, row);

    playSound(SOUND_EFFECTS.MOVE);

    // Handle the picking up of the powerUp.
    if (isOnPowerUp) {
      // @ts-ignore
      removePowerUp(col, row);
      playSound(SOUND_EFFECTS.POWERUP);

      setPreviousPlayers(new Map(allPlayers));
      // @ts-ignore. Caution, ++ instead of + 1 would not work. If one would want to use it, it would have to be ++player[powerUp.type].
      let powerUpValue: number = player[powerUp.type] + 1;
      // @ts-ignore
      global.players = new Map(
        // @ts-ignore
        global.players.set(id, {
          ...player,
          // @ts-ignore
          [powerUp.type]: powerUpValue,
          col,
          row,
        })
      );
      // @ts-ignore
      setPlayers(global.players);

      return;
    }
    // If no PowerUp is there, just update the position of the player.
    // @ts-ignore
    global.players = // @ts-ignore
      new Map(global.players.set(id, { ...player, col, row }));
    // @ts-ignore
    setPlayers(global.players);
  }

  function isOnFieldWithPowerUp(col: number, row: number) {
    let powerUp = powerUpsRef.current.filter(
      (powerUp: PowerUp) => powerUp.col === col && powerUp.row === row
    )[0];

    return [powerUp !== undefined, powerUp];
  }

  function moveIsValid(playerId: number, col: number, row: number) {
    const bombI =
      bombs.filter(
        (bomb: BombType) => bomb.player.col === col && bomb.player.row === row
      ).length <= 0;

    const field =
      defaultMap.filter((field: any) => field.x === col && field.y === row)[0]
        .fieldtype === FIELDS.NORMAL;

    return field && bombI;
  }

  function placeBomb(bombData: PlayerType) {
    // Mabye this could be simplified by using the player data from players (usePlayers-Hook)
    // so only the ID needs to be passed.
    // @ts-ignore
    if (bombPlacementIsPossible(global.players.get(bombData.id))) {
      placeBombWithoutValidation(bombData);
    }
  }

  function shouldUpdatePlayerOnPlacedBombs(id: number) {
    // Update the Players on Singleplayer and Local Multiplayer.
    if (!user.isOnlineMultiplayer) {
      return true;
    }

    // Depending on whether its the player or the remote player, (don't) update the players.
    return user.multiplayerId === id;
  }

  function placeBombWithoutValidation(bombData: PlayerType) {
    // Update the amount of bombs the player laid so he cannot exceed his limit.
    // @ts-ignore
    let player: PlayerType = global.players.get(bombData.id)!;
    let bombsNr: number = player!.placedBombsAmount + 1;

    if (shouldUpdatePlayerOnPlacedBombs(bombData.id)) {
      if (user.multiplayerId === bombData.id || !user.isOnlineMultiplayer) {
        // @ts-ignore
        global.players = new Map(
          // @ts-ignore
          global.players.set(player!.id, {
            ...player,
            placedBombsAmount: bombsNr,
          })
        );
        // @ts-ignore
        setPlayers(global.players);
      }
    }

    setBombsToDraw([
      ...bombsToDraw,
      {
        explosionTimeout: 0,
        player: bombData,
        explosionsOnFields: getBombExplosion(bombData),
      },
    ]);
  }

  function bombPlacementIsPossible(playerData: PlayerType) {
    return (
      playerData.placedBombsAmount < playerData.numberOfMaxBombs &&
      playerData.alive
    );
  }

  function getBombExplosion(bombData: PlayerType): explosionOnField[] {
    let explosionsOnFields: explosionOnField[] = [
      {
        col: bombData.col,
        row: bombData.row,
        direction: { col: 0, row: 0, direction: "none" },
        end: false,
      },
    ];

    directions.forEach((direction) => {
      for (let i = 1; i < bombData.explosionSize; i++) {
        let explosionCol = bombData.col + direction.col * i;
        let explosionRow = bombData.row + direction.row * i;
        const foundField = defaultMap.find(
          (field) => field.x === explosionCol && field.y === explosionRow
        );
        if (foundField !== undefined) {
          if (foundField.fieldtype !== FIELDS.UNDESTROYABLE) {
            explosionsOnFields.push({
              col: explosionCol,
              row: explosionRow,
              direction: direction,
              end:
                foundField.fieldtype === FIELDS.DESTROYABLE ||
                i === bombData.explosionSize - 1,
            });
          }
          if (foundField.fieldtype !== FIELDS.NORMAL) {
            break;
          }
        }
      }
    });
    return explosionsOnFields;
  }

  // Place PowerUps (e.g. passed from WebSocket).
  function placePowerUp(powerUp: PowerUpTypes, col: number, row: number) {
    setPowerUpsToDraw([
      ...powerUpsToDraw,
      {
        type: powerUp,
        row: row,
        col: col,
      },
    ]);
  }

  // Remove a PowerUp. E.g. when an opponent picked it up.
  function removePowerUp(col: number, row: number) {
    if (user.isOnlineMultiplayer) {
      socket.emit("multiplayerAction", {
        type: "REMOVE_POWER_UP",
        col: col,
        row: row,
      });
    }

    setPowerUps(
      powerUpsRef.current.filter(
        (powerUp) => !(powerUp.row === row && powerUp.col === col)
      )
    );
    setPowerUpsToClear([
      ...powerUpsToClear,
      { type: PowerUpTypes.None, col, row },
    ]);

    setTimeout(setPowerUpsToClear, 150, []);
  }

  function addPowerUpToPlayer(id: number, powerUp: PowerUp) {
    let player: PlayerType = players.get(id)!;
    // @ts-ignore
    let globalPlayers = global.players;

    // @ts-ignore
    let powerUpValue: number = player[powerUp.type]++;

    // @ts-ignore
    global.players = new Map(
      // @ ts-ignore
      globalPlayers.set(id, {
        ...player,
        // @ts-ignore
        [powerUp.type]: powerUpValue,
      })
    );

    // @ts-ignore
    setPlayers(global.players);
  }

  let facade = {
    movePlayer,
    placeBomb,
    removePowerUp,
    placePowerUp,
    addPowerUpToPlayer,
    setAllPreviousPlayers,
    moveIsValid,
    moveWithoutValidation,
    placeBombWithoutValidation,
    bombPlacementIsPossible,
  };

  return {
    facade,
  };
}
