import React, { useEffect, useRef } from "react";

export function KeyInput(key: string, callBack: Function) {
  const callbackRef = useRef(callBack);

  useEffect(() => {
    callbackRef.current = callBack;
  });

  useEffect(() => {
    function handle(event: any) {
      if (event.code === key) {
        callbackRef.current(event);
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [key]);
}
