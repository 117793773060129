import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { Link } from "react-router-dom";

interface ButtonProps extends React.ComponentProps<"button"> {
  text: string;
}

export const Button: React.FunctionComponent<ButtonProps> = ({
  text,
  ...buttonProps
}) => {
  const { className, ...otherProps } = buttonProps;
  return (
    <button
      type="button"
      className={"m-1 glass-button gi-Button position-relative " + className}
      style={{ display: "block", ...otherProps.style }}
      {...otherProps}
    >
      {text}
    </button>
  );
};

interface LinkButtonProps extends ButtonProps {
  linkto: string;
  linkclass?: string;
}

export const LinkButton: React.FunctionComponent<LinkButtonProps> = ({
  linkto,
  linkclass,
  ...buttonProps
}) => {
  return (
    <Link to={linkto} className={"gi-LinkButton " + linkclass}>
      <Button
        {...buttonProps}
        className={
          "mt-0 position-relative start-50 translate-middle-x mb-0 " +
          buttonProps.className
        }
      />
    </Link>
  );
};
