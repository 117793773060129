import { PlayerType, usePlayers } from "../../game/PlayerContext";
import useGameFacade from "../../game/useGameFacade";
import { KeyInput } from "../../game/KeyInput";
import { socket } from "../../App";
import { MultiplayerAction } from "./MultiplayerAction";
import { useEffect, useState } from "react";
import { useUser } from "../../account/UserContext";

export default function HandlerMultiplayerOnline() {
  const { players } = usePlayers();
  const { facade } = useGameFacade();
  const [s] = useState(socket);
  const { user } = useUser();

  function handleInput(key: any) {
    let moveToCol;
    let moveToRow;
    let multiplayerId = user.multiplayerId;
    let player: PlayerType = players.get(multiplayerId)!;

    switch (key.code) {
      case "ArrowRight":
        moveToCol = player.col + 1;
        moveToRow = player.row;
        move(multiplayerId, moveToCol, moveToRow);

        break;

      case "ArrowLeft":
        moveToCol = player.col - 1;
        moveToRow = player.row;
        move(multiplayerId, moveToCol, moveToRow);

        break;

      case "ArrowUp":
        moveToCol = player.col;
        moveToRow = player.row - 1;
        move(multiplayerId, moveToCol, moveToRow);

        break;

      case "ArrowDown":
        moveToCol = player.col;
        moveToRow = player.row + 1;
        move(multiplayerId, moveToCol, moveToRow);

        break;

      case "Space":
        // @ts-ignore
        let globalPlayer = global.players.get(multiplayerId);
        if (facade.bombPlacementIsPossible(globalPlayer)) {
          // @ts-ignore
          socket.emit("multiplayerAction", {
            type: "PLACE_BOMB",
            player: globalPlayer,
          });
          // @ts-ignore
          facade.placeBombWithoutValidation(globalPlayer);
        }
        break;
    }
  }

  function move(id: number, col: number, row: number) {
    if (facade.moveIsValid(id, col, row)) {
      facade.moveWithoutValidation(id, col, row);
      socket.emit("multiplayerAction", {
        type: "MOVE",
        playerId: id,
        col: col,
        row: row,
      });
    }
  }

  // If the socket-listener were outside the useEffect, each WS-message would trigger actions multiple times.
  useEffect(() => {
    s.on("multiplayerAction", (data: MultiplayerAction) => {
      switch (data.type!) {
        case "MOVE":
          facade.moveWithoutValidation(data.playerId!, data.col!, data.row!);
          break;

        case "PLACE_BOMB":
          facade.placeBombWithoutValidation(data.player!);
          break;

        case "REMOVE_POWER_UP":
          facade.removePowerUp(data.col!, data.row!);
          break;

        case "PLACE_POWER_UP":
          facade.placePowerUp(data.powerUp!, data.col!, data.row!);
          break;
      }
    });
  }, [s]);

  for (const key of [
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Space",
  ]) {
    KeyInput(key, handleInput);
  }
  return <></>;
}
