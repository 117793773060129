import Game from "../../game/Game";
import HandlerMultiplayerOnline from "./HandlerMultiplayerOnline";
import { numberOfRealPlayers, useUser } from "../../account/UserContext";
import { useEffect } from "react";
import AiHandler from "../../game/ai/AiHandler";

export default function OnlineMultiplayer() {
  const { user, setUser } = useUser();
  let players: number[] = [];

  if (user.groupId === "") {
    for (let i = numberOfRealPlayers + 1; i <= 4; i++) {
      players.push(i);
    }
  }

  useEffect(() => {
    setUser({ ...user, isOnlineMultiplayer: true });
  }, []);

  return (
    <>
      <HandlerMultiplayerOnline />
      <AiHandler aiPlayers={players} online={true} />
      <Game />
    </>
  );
}
