import FieldGridRenderer from "./FieldGridRenderer";
import { FieldWrapper } from "./FieldWrapper";
import { forwardRef, useImperativeHandle, useRef } from "react";
import { useFields } from "./FieldsContext";

export type GameGrid = {
  fields: FieldWrapper[];
};

function FieldsRenderer(props: any, ref: any) {
  const fieldGridRendererRef = useRef();
  const { defaultMap } = useFields();
  useImperativeHandle(ref, () => ({
    getMapData() {
      if (fieldGridRendererRef.current) {
        // @ts-ignore
        return defaultMap;
      }
    },
    updateMapField(col: number, row: number) {
      if (fieldGridRendererRef.current) {
        // @ts-ignore
        fieldGridRendererRef.current.updateMapField(col, row);
      }
    },
  }));

  return <FieldGridRenderer ref={fieldGridRendererRef} />;
}

export default forwardRef(FieldsRenderer);
