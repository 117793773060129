import { KeyInput } from "./KeyInput";
import { usePlayers } from "./PlayerContext";
import useGameFacade from "./useGameFacade";

export default function KeyboardHandler() {
  const { players } = usePlayers();
  const { facade } = useGameFacade();

  function handleInput(key: any) {
    let moveToCol;
    let moveToRow;

    switch (key.code) {
      case "ArrowRight":
        moveToCol = players.get(1)!.col + 1;
        moveToRow = players.get(1)!.row;
        facade.movePlayer(1, moveToCol, moveToRow);

        break;

      case "ArrowLeft":
        moveToCol = players.get(1)!.col - 1;
        moveToRow = players.get(1)!.row;
        facade.movePlayer(1, moveToCol, moveToRow);

        break;

      case "ArrowUp":
        moveToCol = players.get(1)!.col;
        moveToRow = players.get(1)!.row - 1;
        facade.movePlayer(1, moveToCol, moveToRow);

        break;

      case "ArrowDown":
        moveToCol = players.get(1)!.col;
        moveToRow = players.get(1)!.row + 1;
        facade.movePlayer(1, moveToCol, moveToRow);

        break;
    }
  }

  for (const key of ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight"]) {
    KeyInput(key, handleInput);
  }

  function placeBomb() {
    // @ts-ignore
    facade.placeBomb(global.players.get(1)!);
  }

  KeyInput("Space", placeBomb);

  return <></>;
}
