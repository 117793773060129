import { useEffect, useRef } from "react";
import PlayerRenderer from "./PlayerRenderer";
import BombsRenderer, { PowerUpTypes } from "./BombsRenderer";
import FieldsRenderer from "./FieldsRenderer";
import { BackgroundImage } from "../common/BackgroundImage";

type directionMove = "up" | "down" | "left" | "right" | "none";
export type direction = {
  row: number;
  col: number;
  direction: directionMove;
};
export const directions: direction[] = [
  {
    row: -1,
    col: 0,
    direction: "up",
  },
  {
    row: 1,
    col: 0,
    direction: "down",
  },
  {
    row: 0,
    col: -1,
    direction: "left",
  },
  {
    row: 0,
    col: 1,
    direction: "right",
  },
];

export default function Game() {
  const bombRef = useRef();
  const playerRef = useRef();
  const fieldRef = useRef();
  const playerId = 1;

  useEffect(() => {
    // @ts-ignore
    global.isInGame = true;
  }, []);

  const getPlayerByIdRef = () => {
    if (playerRef.current) {
      // @ts-ignore
      return playerRef.current.getPlayerById(playerId);
    }
  };
  const getPlayersRef = () => {
    if (playerRef.current) {
      // @ts-ignore
      return playerRef.current.getPlayers();
    }
  };
  const playerDied = (id: number) => {
    if (playerRef.current) {
      // @ts-ignore
      playerRef.current.playerDied(id);
    }
  };

  const placePowerUp = (powerUp: PowerUpTypes, col: number, row: number) => {
    if (bombRef.current) {
      // @ts-ignore
      bombRef.current.placePowerUp(powerup, col, row);
    }
  };

  function updateFieldOnMap(col: number, row: number) {
    if (fieldRef.current) {
      // @ts-ignore
      fieldRef.current.updateMapField(col, row);
    }
  }

  return (
    <BackgroundImage image="nathan-watson">
      <div className={"glass-menu game-background"}>
        <div className={"game-wrapper"}>
          <PlayerRenderer ref={playerRef} />
          <BombsRenderer
            ref={bombRef}
            getPlayerById={getPlayerByIdRef}
            getPlayers={getPlayersRef}
            playerDied={playerDied}
            updateField={updateFieldOnMap}
            placePowerUp={placePowerUp}
          />
          <FieldsRenderer ref={fieldRef} />
        </div>
      </div>
    </BackgroundImage>
  );
}
