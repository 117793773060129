export const BackgroundImage = (props: any) => {
  return (
    <div
      style={{ backgroundImage: `url('/img/${props.image}.webp')` }}
      className="background-image"
    >
      {props.children}
    </div>
  );
};
