import { Message } from "./Message";
import { useUser } from "../account/UserContext";

export default function ChatBubble(message: Message) {
  const { user } = useUser();
  return (
    <div
      key={message.content.substring(0, 25) + message.author + Math.random()} // Using Math.random() is okay here, because it is only an additional security when the same message is sent twice. So there is no need to have an actual completely random number.
      className={
        "m-1 w-75 p-1 chat-bubble " +
        (message.author === user.username
          ? "ms-auto chat-bubble-own"
          : "chat-bubble-opponent")
      }
    >
      <p className="fw-light mb-0">{message.author}</p>
      <p className={"mb-0"}>{message.content}</p>
    </div>
  );
}
