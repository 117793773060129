import { PlayerType } from "../PlayerContext";
import useGameFacade from "../useGameFacade";
import { useEffect, useRef } from "react";
import { useBombs } from "../BombsContext";
import { AiAgent } from "./AiAgent";
import { useFields } from "../FieldsContext";
import { BombType } from "../BombsRenderer";
import { direction, directions } from "../Game";
import { FIELDS } from "../Fields";
import { location } from "./AiPathfinding";
import { socket } from "../../App";

export default function AiHandler(props: any) {
  type AiData = {
    aiAgent: AiAgent;
    aiPlayerId: number;
    attackDelay: boolean;
  };

  const { bombs } = useBombs();
  const { defaultMap } = useFields();
  const { facade } = useGameFacade();
  let aiPlayers: PlayerType[] = [];
  let aiDatas: AiData[] = [];

  const bombsRef = useRef(bombs);
  bombsRef.current = bombs;

  useEffect(() => {
    // @ts-ignore
    global.players.forEach((player) => {
      // @ts-ignore
      global.players = new Map(
        // @ts-ignore
        global.players.set(player.id, {
          ...player,
          ai: props.aiPlayers.indexOf(player.id) !== -1,
        })
      );
    });
    // @ts-ignore
    aiPlayers = Array.from(global.players.values()).filter(
      // @ts-ignore
      (player) => player.ai
    );
    aiPlayers.forEach((aiPlayer) => {
      if (aiPlayer.alive) {
        aiDatas.push({
          aiAgent: new AiAgent(aiPlayer),
          aiPlayerId: aiPlayer.id,
          attackDelay: false,
        });
      }
    });
    aiDatas.forEach((aiData) => {
      triggerAiActionTimer(aiData);
    });
  }, []);

  function triggerAiActionTimer(aiData: AiData) {
    setTimeout(aiAction, randomActionTime(700, 1200), aiData);
  }

  function randomActionTime(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function aiAction(aiData: AiData) {
    // @ts-ignore
    if (!global.isInGame) {
      return;
    }

    //@ts-ignore
    const alivePlayer = Array.from(global.players.values()).filter(
      //@ts-ignore
      (player) => player.alive && player.ai
    );
    if (alivePlayer.length > 0) {
      let action: location = { x: 0, y: 0 };
      let dodge = false;

      // @ts-ignore
      const aiPlayer = global.players.get(aiData.aiPlayerId)!;
      if (aiPlayer?.alive ?? true) {
        const bombsWhereAiIsIn = aiInBombs(aiPlayer);
        if (!aiPlayer.attackDelay) {
          playerInAttackRange(aiData, aiPlayer);
        }
        if (bombsWhereAiIsIn.length > 0) {
          dodge = true;
          aiData.aiAgent.clearPendingActions();
          action = dodgeBombs(bombsWhereAiIsIn, aiPlayer);
        } else {
          action = aiData.aiAgent.nextMove(
            defaultMap,
            { x: aiPlayer.col, y: aiPlayer.row },
            bombsRef.current
          );
        }
        if (
          action.x > 0 &&
          action.y > 0 &&
          (!nextMoveIsInBomb(action) || dodge)
        ) {
          if (
            defaultMap.find(
              (field) => field.x === action.x && field.y === action.y
            )!.fieldtype === FIELDS.DESTROYABLE
          ) {
            aiData.aiAgent.clearPendingActions();
            // @ts-ignore
            placeBomb(global.players.get(aiData.aiPlayerId)!);
          } else {
            move(aiPlayer.id, action.x, action.y);
          }
        } else {
          aiData.aiAgent.clearPendingActions();
        }
      } else {
        aiDatas = aiDatas.filter((toFilterAiData) => toFilterAiData !== aiData);
      }

      triggerAiActionTimer(aiData);
    }
  }

  function placeBomb(player: PlayerType) {
    if (facade.bombPlacementIsPossible(player)) {
      if (props.online) {
        // @ts-ignore
        socket.emit("multiplayerAction", {
          type: "PLACE_BOMB",
          player: player,
        });
      }
      // @ts-ignore
      facade.placeBombWithoutValidation(player);
    }
  }

  function move(id: number, col: number, row: number) {
    if (facade.moveIsValid(id, col, row)) {
      facade.setAllPreviousPlayers();
      facade.moveWithoutValidation(id, col, row);
      if (props.online) {
        socket.emit("multiplayerAction", {
          type: "MOVE",
          playerId: id,
          col: col,
          row: row,
        });
      }
    }
  }

  function playerInAttackRange(
    currentPlayerData: AiData,
    currentPlayer: PlayerType
  ) {
    //@ts-ignore
    const allPlayers = global.players;
    // @ts-ignore
    const otherPlayers = Array.from(allPlayers.values()).filter(
      // @ts-ignore
      (player) => player !== currentPlayer && player.alive
    );
    const xMin = currentPlayer.col + currentPlayer.explosionSize;
    const xMax = currentPlayer.col - currentPlayer.explosionSize;
    const yMin = currentPlayer.row + currentPlayer.explosionSize;
    const yMax = currentPlayer.row - currentPlayer.explosionSize;

    for (let i = 0; i < otherPlayers.length; i++) {
      if (
        // @ts-ignore
        inRange(otherPlayers[i].col, xMin, xMax) &&
        // @ts-ignore
        inRange(otherPlayers[i].row, yMin, yMax) &&
        !currentPlayerData.attackDelay
      ) {
        facade.placeBomb(currentPlayer);
        currentPlayerData.attackDelay = true;
        setTimeout(() => {
          currentPlayerData.attackDelay = false;
        }, 2500);
      }
    }
  }

  function inRange(x: number, min: number, max: number): boolean {
    return (x - min) * (x - max) <= 0;
  }

  function nextMoveIsInBomb(moveTo: location) {
    let isInBomb = false;
    for (let i = 0; i < bombsRef.current.length; i++) {
      const bomb: BombType = bombsRef.current[i];
      const foundField = bomb.explosionsOnFields.find(
        (field) => field.col === moveTo.x && field.row === moveTo.y
      );
      if (foundField !== undefined) {
        isInBomb = true;
        break;
      }
    }
    return isInBomb;
  }

  type AiInExplosion = {
    bomb: BombType;
    direction: direction;
  };

  function dodgeBombs(bombsWhereAiIsIn: AiInExplosion[], aiPlayer: PlayerType) {
    let action: location = { x: 0, y: 0 };
    let triedDirections: direction[] = [{ col: 0, row: 0, direction: "none" }];
    for (let i = 0; i < bombsWhereAiIsIn.length; i++) {
      const bombWhereAiIsIn = bombsWhereAiIsIn[i];
      if (
        triedDirections.filter(
          (direction) =>
            direction.direction === bombWhereAiIsIn.direction.direction
        ).length < 1
      ) {
        action = findFieldAndGetAction(
          bombWhereAiIsIn.direction,
          aiPlayer,
          bombsWhereAiIsIn
        );
        if (action.x !== 0 && action.y !== 0) {
          break;
        }
        triedDirections.push(bombWhereAiIsIn.direction);
      }
    }

    if (action.x === 0 && action.y === 0) {
      const shuffledDirections = directions
        .map((value) => ({ value, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map(({ value }) => value);
      for (let i = 0; i < shuffledDirections.length; i++) {
        const direction = shuffledDirections[i];
        if (triedDirections.indexOf(direction) === -1) {
          action = findFieldAndGetAction(direction, aiPlayer, bombsWhereAiIsIn);
          if (action.x !== 0 && action.y !== 0) {
            break;
          }
        }
        triedDirections.push(direction);
      }
    }
    return action;
  }

  function findFieldAndGetAction(
    direction: direction,
    aiPlayer: PlayerType,
    bombsWhereAiIsIn: AiInExplosion[]
  ): location {
    const moveToCol = aiPlayer.col + direction.col;
    const moveToRow = aiPlayer.row + direction.row;

    const foundBomb = bombsWhereAiIsIn.find(
      (bombWhereAiIsIn) =>
        bombWhereAiIsIn.bomb.player.col === moveToCol &&
        bombWhereAiIsIn.bomb.player.row === moveToRow
    );

    const foundField = defaultMap.find(
      (field) => field.x === moveToCol && field.y === moveToRow
    );
    if (
      foundField !== undefined &&
      foundBomb === undefined &&
      foundField.fieldtype === FIELDS.NORMAL
    ) {
      return {
        x: foundField.x,
        y: foundField.y,
      };
    }
    return { x: 0, y: 0 };
  }

  function aiInBombs(aiPlayer: PlayerType): AiInExplosion[] {
    let bombsWhereAiIsIn: AiInExplosion[] = [];
    bombsRef.current.forEach((bomb) => {
      const foundField = bomb.explosionsOnFields.find(
        (field) => field.col === aiPlayer.col && field.row === aiPlayer.row
      );
      if (foundField !== undefined) {
        bombsWhereAiIsIn.push({ bomb: bomb, direction: foundField.direction });
      }
    });
    return bombsWhereAiIsIn;
  }
  return <></>;
}
