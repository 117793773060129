import React, { createContext, useContext, useState } from "react";
import { UserContextHookType, UserContextType } from "./UserContextType";
import { socket } from "../App";

export const UserContext = createContext<UserContextHookType>({
  user: {
    username: "NoName",
    loggedIn: false,
    multiplayerId: 5,
    isOnlineMultiplayer: false,
    groupId: "",
  },
  setUser: () => {},
});

export let numberOfRealPlayers = 1;

// @ts-ignore
export default function UserProvider({ children }) {
  const [user, setUser] = useState<UserContextType>({
    // Math.random is added so that users that are not logged in don't have the same name.
    username: "NoName-" + Math.random().toString(26).slice(-6),
    loggedIn: false,
    multiplayerId: 5,
    isOnlineMultiplayer: false,
    groupId: "",
  });

  // Store username in memory, once login is successful.
  socket.on("login", (msg) => {
    setUser({
      username: msg.message.user_metadata.username,
      loggedIn: true,
      multiplayerId: 5,
      isOnlineMultiplayer: false,
      groupId: "",
    });
  });

  // Determine the users player for this group.
  socket.on("gameStarted", (group) => {
    numberOfRealPlayers = group.length;
    let id = group.findIndex((el: string) => el === socket.id) + 1;
    setUser({ ...user, multiplayerId: id });
  });

  // Pass on values around the user.
  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}
