import KeyboardHandler from "../game/KeyboardHandler";
import Game from "../game/Game";
import React from "react";
import AiHandler from "../game/ai/AiHandler";

export default function SinglePlayer() {
  return (
    <>
      <KeyboardHandler />
      <AiHandler aiPlayers={[2, 3, 4]} online={false} />
      <Game />
    </>
  );
}
