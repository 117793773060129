import React, { useState } from "react";

export default function Credits() {
  const [showCredits, setShowCredits] = useState(false);

  return (
    <>
      <h3>
        <i className={"bi-person-lines-fill me-1"} />
        Credits
        <div
          className={"fs-6 d-inline-block position-absolute end-0 pt-2 pe-4"}
          onClick={() => setShowCredits(!showCredits)}
          style={{ cursor: "pointer" }}
        >
          {showCredits ? (
            <>
              <i className={"bi-eye-slash-fill me-1"} />
              hide
            </>
          ) : (
            <>
              <i className={"bi-eye-fill me-1"} />
              show
            </>
          )}
        </div>
      </h3>
      {showCredits ? (
        <>
          <h4>
            <i className={"bi-code-slash me-1"} />
            Developers
          </h4>
          <p>
            Globus Incendiarius has been developed by Kim Nunes and Matthias
            Fuhrimann for the FWebT-Module @ FFHS.
          </p>
          <h4>
            <i className={"bi-file-earmark-music me-1"} />
            Soundtracks
          </h4>
          <ul>
            <li>
              Rammstein - Deutschland by{" "}
              <a href={"https://www.youtube.com/watch?v=3VReIuv1GFo"}>Noplan</a>
            </li>
            <li>
              Sabaton - Great War by{" "}
              <a href={"https://www.youtube.com/watch?v=NyNuEnwP6VQ"}>
                Epic-NES-Metal
              </a>
            </li>
            <li>
              Rammstein - Du Hast by{" "}
              <a href={"https://www.youtube.com/watch?v=sGCqsy4X28o"}>
                8 Bit Universe
              </a>
            </li>
            <li>
              Gorillaz - Feel Good Inc. by{" "}
              <a href={"https://www.youtube.com/watch?v=MltJnhBLGtw"}>
                8 Bit Universe
              </a>
            </li>
          </ul>
          <h4>
            <i className={"bi-file-earmark-diff me-1"} />
            Effects
          </h4>
          <ul>
            <li>
              Soft Grass Foot Step by{" "}
              <a href={"https://freesound.org/people/DRFX/sounds/347371/"}>
                DRFX
              </a>
            </li>
            <li>
              Retro Bonus Pickup SFX by{" "}
              <a href={"https://freesound.org/people/suntemple/sounds/253172/"}>
                suntemple
              </a>
            </li>
            <li>
              Explosion by{" "}
              <a href={"https://freesound.org/people/derplayer/sounds/587194/"}>
                derplayer
              </a>
            </li>
          </ul>
          <h4>
            <i className={"bi-file-earmark-image me-1"} />
            Graphics
          </h4>
          <ul>
            <li>Logo by Maya Hartmeier</li>
            <li>Background Image by Nathan Watson</li>
          </ul>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
