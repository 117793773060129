import { createContext, useContext, useState } from "react";
import { PowerUpTypes } from "./BombsRenderer";

export type PowerUp = {
  row: number;
  col: number;
  type: PowerUpTypes;
};

type PowerUpContextHookType = {
  powerUps: PowerUp[];
  setPowerUps: Function;
  powerUpsToDraw: PowerUp[];
  setPowerUpsToDraw: Function;
  powerUpsToClear: PowerUp[];
  setPowerUpsToClear: Function;
  resetPowerUps: Function;
};

export const PowerUpContext = createContext<PowerUpContextHookType>({
  powerUps: [],
  setPowerUps: () => {},
  powerUpsToDraw: [],
  setPowerUpsToDraw: () => {},
  powerUpsToClear: [],
  setPowerUpsToClear: () => {},
  resetPowerUps: () => {},
});

// @ts-ignore
export default function PowerUpProvider({ children }) {
  const [powerUps, setPowerUps] = useState<PowerUp[]>([]);
  const [powerUpsToDraw, setPowerUpsToDraw] = useState<PowerUp[]>([]);
  const [powerUpsToClear, setPowerUpsToClear] = useState<PowerUp[]>([]);

  function resetPowerUps() {
    setPowerUps([]);
    setPowerUpsToDraw([]);
  }

  return (
    <PowerUpContext.Provider
      value={{
        powerUps,
        setPowerUps,
        powerUpsToDraw,
        setPowerUpsToDraw,
        powerUpsToClear,
        setPowerUpsToClear,
        resetPowerUps,
      }}
    >
      {children}
    </PowerUpContext.Provider>
  );
}

// Typing the custom Hook as described here: https://devtrium.com/posts/react-typescript-how-to-type-hooks#bonus-how-to-type-custom-hooks
export const usePowerUps = (): PowerUpContextHookType => {
  return useContext(PowerUpContext);
};
