import { useMusic } from "../sounds/MusicContext";
import { SONGS } from "../sounds/Songs";

export default function MusicSettings() {
  const { isPlaying, setIsPlaying, currentSong, setCurrentSong } = useMusic();

  return (
    <div>
      <label htmlFor={"music-select"} className={"d-block"}>
        Choose your battle-music!
      </label>
      <div>
        <select
          onChange={(event) => {
            setCurrentSong(event.target.value);
          }}
          value={currentSong}
          id={"music-select"}
          className={"align-middle"}
        >
          {Object.keys(SONGS).map((key, count) => (
            <option key={count} value={key}>
              {SONGS[key as keyof typeof SONGS]}
            </option>
          ))}
        </select>
        <span>
          {isPlaying ? (
            <i
              className={"bi-volume-mute-fill align-middle ms-2"}
              onClick={() => setIsPlaying(false)}
              style={{ fontSize: "1.5em", cursor: "pointer" }}
            />
          ) : (
            <i
              className={"bi-volume-up-fill align-middle ms-2"}
              onClick={() => setIsPlaying(true)}
              style={{ fontSize: "1.5em", cursor: "pointer" }}
            />
          )}
        </span>
      </div>
    </div>
  );
}
