import React, { createContext, useContext, useEffect, useState } from "react";
import { ChatContextHookType, ChatContextType } from "./ChatContextType";
import { socket } from "../App";
import { useUser } from "../account/UserContext";

export const ChatContext = createContext<ChatContextHookType>({
  messages: {},
  sendMessage: () => {},
  scope: "global",
  setScope: () => {},
});

// @ts-ignore
export default function ChatProvider({ children }) {
  const [messages, setAllMessages] = useState<ChatContextType>({
    global: [],
    lobby: [],
  });
  const [scope, setScope] = useState<string>("global");
  const { user } = useUser();

  const sendMessage = (message: string, scope: string) => {
    socket.emit("message", {
      scope,
      author: user.username,
      message,
    });
  };

  const addMessage = (message: string, author: string, group: string) => {
    setAllMessages({
      ...messages,
      [group]: [
        ...messages[group as keyof ChatContextType]!,
        { author: author, content: message },
      ],
    });
  };

  // When a new message arrives, add it to the according scope.
  socket.on("message", (msg) => {
    addMessage(msg.message, msg.author, msg.scope);
  });

  // Pass on values around the Chat.
  return (
    <ChatContext.Provider value={{ messages, sendMessage, scope, setScope }}>
      {children}
    </ChatContext.Provider>
  );
}

export function useChat() {
  return useContext(ChatContext);
}
