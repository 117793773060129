import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { LinkButton } from "../common/Button";
import { BackgroundImage } from "../common/BackgroundImage";
import { WeatherWidget } from "./WeatherWidget";

export default function Home() {
  return (
    <BackgroundImage image="nathan-watson">
      <div
        className="position-absolute top-50 start-50 translate-middle glass-menu"
        id="home-menu"
      >
        <img
          src="/img/logo.png"
          id={"index-logo"}
          className={"mt-2 d-block mx-auto"}
          alt="Logo von Globus Incendiarius"
        />
        <div
          className="d-grid mt-0 position-absolute top-50"
          style={{ width: "100%" }}
        >
          <LinkButton
            linkto={"/singleplayer"}
            text="Singleplayer"
            linkclass={"mb-2"}
            className={"gi-linkButton-home"}
          />
          <LinkButton
            linkto={"/multiplayer/local"}
            text="Dualplayer"
            linkclass={"mb-2"}
            className={"gi-linkButton-home"}
          />
          <LinkButton
            linkto={"/multiplayer/lobby"}
            text="Online Multiplayer"
            linkclass={"mb-2"}
            className={"gi-linkButton-home"}
          />
          <LinkButton
            linkto={"login"}
            text={"Login"}
            linkclass={"mb-2"}
            className={"gi-linkButton-home"}
          />
          <LinkButton
            linkto={"register"}
            text="Registrieren"
            className={"gi-linkButton-home"}
          />
        </div>
      </div>
      <WeatherWidget />
    </BackgroundImage>
  );
}
