import React from "react";
import { useChat } from "./ChatContext";

export default function ChatInput() {
  const { sendMessage, scope } = useChat();

  const send = () => {
    const inputField: HTMLInputElement = document.getElementById(
      "chat-input-field"
    ) as HTMLInputElement;
    const value: string = inputField.value;

    sendMessage(value, scope);
    inputField.value = "";
  };

  return (
    <div className="input-group position-absolute bottom-0">
      <input
        type="text"
        className="form-control"
        placeholder="Message..."
        aria-label="Message Input Field"
        aria-describedby="button-addon2"
        id="chat-input-field"
      />
      <button
        className="btn btn-outline-secondary"
        type="button"
        id="button-addon2"
        onClick={send}
      >
        Send
      </button>
    </div>
  );
}
