import { createContext, useContext, useState } from "react";
import mapDefault from "../maps/default.json";
import { FIELDS } from "./Fields";
import { FieldWrapper } from "./FieldWrapper";
import _ from "lodash";

const defaultMapData = _.cloneDeep(Object.assign({}, mapDefault));

export type Map = {
  fields: FieldWrapper[];
};

type FieldsContextHookType = {
  defaultMap: FieldWrapper[];
  setDefaultMap: Function;
  updateField: Function;
  resetFields: Function;
};

export const FieldsContext = createContext<FieldsContextHookType>({
  defaultMap: [],
  setDefaultMap: () => {},
  updateField: () => {},
  resetFields: () => {},
});

// @ts-ignore
export default function FieldsProvider({ children }) {
  const [defaultMap, setDefaultMap] = useState(
    Object.assign({}, defaultMapData).fields as any
  );

  function updateField(col: number, row: number) {
    setDefaultMap([...defaultMap, { fieldtype: "NORMAL", x: col, y: row }]);
    defaultMap.find(
      (field: any) => field.x === col && field.y === row
    ).fieldtype = FIELDS.NORMAL;
  }

  function resetFields() {
    setDefaultMap(_.cloneDeep(Object.assign({}, mapDefault)).fields as any);
  }

  return (
    <FieldsContext.Provider
      value={{ defaultMap, setDefaultMap, updateField, resetFields }}
    >
      {children}
    </FieldsContext.Provider>
  );
}

// Typing the custom Hook as described here: https://devtrium.com/posts/react-typescript-how-to-type-hooks#bonus-how-to-type-custom-hooks
export const useFields = (): FieldsContextHookType => {
  return useContext(FieldsContext);
};
