import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { PlayerType, usePlayers } from "./PlayerContext";
// Workaround: Stupid but goes
let firstLoad = [true, true, true, true];

function PlayerRenderer(props: any, ref: any) {
  useImperativeHandle(ref, () => ({
    getPlayerById(playerId: number) {
      return players.get(playerId);
    },
    getPlayers() {
      return players;
    },
    playerDied(id: number) {
      let player = players.get(id);
      setPreviousPlayers(
        // @ts-ignore
        new Map(players.set(id, { ...player, alive: false }))
      );
      // @ts-ignore
      setPlayers(new Map(players.set(id, { ...player, alive: false })));
    },
  }));

  const canvasRef = useRef<HTMLCanvasElement>(null);
  let context: CanvasRenderingContext2D | null;

  let spriteSheet = new Image();
  const blockSize = 64;
  const spriteSize = 32;

  const { players, setPlayers, previousPlayers, setPreviousPlayers } =
    usePlayers();

  // CAUTION, the order of these useEffects is important and should not be changed.

  // Set the correct context
  useEffect(() => {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;
  });

  // Listen to changes to single player to make it possible to cleanup the old position.
  useEffect(() => {
    cleanUpOldPosition(1);
  }, [players.get(1)]);
  useEffect(() => {
    cleanUpOldPosition(2);
  }, [players.get(2)]);
  useEffect(() => {
    cleanUpOldPosition(3);
  }, [players.get(3)]);
  useEffect(() => {
    cleanUpOldPosition(4);
  }, [players.get(4)]);

  // Render the players at their current position.
  useEffect(() => {
    // Workaround: Stupid but goes
    firstLoad = [true, true, true, true];

    spriteSheet.onload = () => {
      players.forEach((player, id) => {
        drawPlayer(id);
      });
    };
    spriteSheet.src = `/art/default/spriteSheet.png`;
  }, []);

  // At last, set the new players as the previousPlayers. This way it is possible for the hooks above to
  // get the previous state of the players, once players is updated. This is needed to cleanup the old position.
  useEffect(() => {
    setPreviousPlayers(new Map(players));
  }, [players]);

  function drawPlayer(playerId: number) {
    // Catch invalid PlayerID, so it is safe to assume the .get() below returns a PlayerType.
    if (playerId < 1 || playerId > 4) {
      throw new Error(
        "PlayerID is not valid. Must be between 1 and 4, but is: " + playerId
      );
    }

    let player = players.get(playerId) as PlayerType;
    if (player.alive) {
      const x = player.col * blockSize;
      const y = player.row * blockSize;

      context?.drawImage(
        spriteSheet,
        player.spriteX * spriteSize,
        player.spriteY * spriteSize + 128 * (playerId - 1),
        spriteSize,
        spriteSize,
        x,
        y,
        64,
        64
      );
    }
  }

  function cleanUpOldPosition(id: number) {
    if (firstLoad[id - 1]) {
      firstLoad[id - 1] = false;
    } else {
      const player = previousPlayers.get(id)!;
      context?.clearRect(
        player.col * blockSize,
        player.row * blockSize,
        blockSize,
        blockSize
      );
      spriteSheet.onload = () => {
        drawPlayer(id);
      };
      spriteSheet.src = `/art/default/spriteSheet.png`;
    }
  }

  return (
    <canvas
      height={"832px"}
      width={"960px"}
      ref={canvasRef}
      style={{ position: "absolute", top: 0, left: 0 }}
      data-testid={"player-renderer"}
    />
  );
}

export default forwardRef(PlayerRenderer);
