import { SOUND_EFFECTS } from "../sounds/SoundEffects";

export function playSound(sound: SOUND_EFFECTS) {
  if (localStorage.getItem("playSoundEffects") === "true") {
    let audio = new Audio("/music/" + sound + ".mp3");
    audio.volume = 0.5;
    audio.loop = false;
    audio.muted = true;
    audio.play().then(() => (audio.muted = false));
  }
}

export function stopSound() {
  localStorage.setItem("playSoundEffects", "false");
  setTimeout(() => localStorage.setItem("playSoundEffects", "true"), 5000);
}
