import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FIELDS } from "./Fields";
import { PlayerType, usePlayers } from "./PlayerContext";
import { useBombs } from "./BombsContext";
import { PowerUp, usePowerUps } from "./PowerUpContext";
import { useFields } from "./FieldsContext";
import { direction } from "./Game";
import { FieldWrapper } from "./FieldWrapper";
import { playSound } from "../utils/playSound";
import { SOUND_EFFECTS } from "../sounds/SoundEffects";
import { Button } from "../common/Button";
import { showWinningBox } from "../utils/showWinningBox";
import { useNavigate } from "react-router-dom";
import { socket } from "../App";
import { useUser } from "../account/UserContext";

export type explosionOnField = {
  col: number;
  row: number;
  direction: direction;
  end: boolean;
};
export type BombType = {
  explosionTimeout: number;
  player: PlayerType; //TODO: Improvement: Only necessary data
  explosionsOnFields: explosionOnField[];
};

export enum PowerUpTypes {
  "explosionSize" = "explosionSize",
  "numberOfMaxBombs" = "numberOfMaxBombs",
  "None" = "None",
}

function BombsRenderer(props: any, ref: any) {
  // ### Imperative Handlers to expose functions to parent. ###
  useImperativeHandle(ref, () => ({
    getBombs() {
      return bombs;
    },
    getPowerUps() {
      return powerUps;
    },
    placePowerUp(powerUp: PowerUpTypes, col: number, row: number) {
      dropPowerUp(col, row, powerUp);
    },
  }));

  // ### Hooks & State initialization. ###
  const { bombs, setBombs, bombsToDraw, setBombsToDraw } = useBombs();
  const [probabilityBorders] = useState<number[]>([]);
  const [powerUpKeys, setPowerUpKeys] = useState<PowerUpTypes[]>([]);
  const {
    powerUps,
    setPowerUps,
    powerUpsToClear,
    powerUpsToDraw,
    setPowerUpsToDraw,
  } = usePowerUps();
  const { setPlayers, resetPlayers } = usePlayers();
  const { updateField, defaultMap, resetFields } = useFields();
  const { user } = useUser();
  const navigate = useNavigate();

  // ### Refs initialization ###

  // Otherwise, the setTimeout won't use the actual/current state.
  const bombsRef = useRef(bombs);
  bombsRef.current = bombs;

  let probabilityBordersRef = useRef(probabilityBorders);
  probabilityBordersRef.current = probabilityBorders;

  const powerUpsRef = useRef(powerUps);
  powerUpsRef.current = powerUps;

  // ## Some more initialization ##
  let powerUpsWithChances: any = {
    explosionSize: 0.4, // 6% Chance on explosion, when PowerUp Drops (40%)
    numberOfMaxBombs: 0.6, // 9% Chance on explosion, when PowerUp Drops (60%)
  };
  let dropProbability = 0.15; // 15% General drops

  const canvasRef = useRef<HTMLCanvasElement>(null);
  let context: CanvasRenderingContext2D | null;
  const spriteSheet = new Image();
  const blockSize = 64;

  let intermediatePowerUps: PowerUp[] = [];

  // Set the correct context.
  useEffect(() => {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;
  });

  useEffect(() => {
    if (bombsToDraw.length > 0) {
      let explosionTimeOut: any;
      // Get the newest bomb.
      let bombToDraw: BombType = bombsToDraw[bombsToDraw.length - 1];

      // Onload is before src declaration. If it were not, it would be possible that the callback was never
      // triggered since the image is loaded before the callback is initialized.
      spriteSheet.onload = () => {
        drawBomb(bombToDraw.player.col, bombToDraw.player.row);
      };
      explosionTimeOut = setTimeout(() => explosion(bombToDraw.player), 3000);

      // If the spritesheet does not get loaded here, it won't render the bomb and explosion for some reason.
      spriteSheet.src = `/art/default/spriteSheet.png`;

      // Add the now drown bomb to the array of bombs.
      setBombs([
        ...bombs,
        {
          explosionTimeout: explosionTimeOut,
          player: bombToDraw.player,
          explosionsOnFields: bombToDraw.explosionsOnFields,
        },
      ]);

      // Remove now drawn bomb from the array.
      // Maybe we need to add a timestamp, so we can differentiate between different bombs of the same player.
      setBombsToDraw(
        bombsToDraw.filter((bomb) => bomb.player.id !== bombToDraw.player.id)
      );
    }
  }, [bombsToDraw]);

  // Draw Bombs (from Facade).
  useEffect(() => {
    if (powerUpsToDraw.length > 0) {
      // Get the newest PowerUp.
      let powerUpToDraw: PowerUp = powerUpsToDraw[powerUpsToDraw.length - 1];
      spriteSheet.onload = () => {
        drawPowerUp(powerUpToDraw.col, powerUpToDraw.row, powerUpToDraw.type);
      };

      // If the spritesheet does not get loaded here, it won't render the powerUp.
      spriteSheet.src = `/art/default/spriteSheet.png`;

      setPowerUpsToDraw(
        powerUpsToDraw.filter(
          (powerUp) =>
            !(
              powerUp.col === powerUpToDraw.col &&
              powerUp.row === powerUpToDraw.row
            )
        )
      );
    }
  }, [powerUpsToDraw]);

  useEffect(() => {
    spriteSheet.src = `/art/default/SpriteSheet.png`;

    let powerUpKeys: any = [];

    for (let key in powerUpsWithChances) {
      powerUpKeys.push(
        (PowerUpTypes[key as keyof typeof PowerUpTypes] as PowerUpTypes) ??
          PowerUpTypes.None
      );

      const value = probabilityBorders[probabilityBorders.length - 1] ?? 0;
      probabilityBorders.push(
        value + powerUpsWithChances[key] * dropProbability
      );
    }
    setPowerUpKeys(powerUpKeys);
  }, []);

  useEffect(() => {
    powerUpsToClear.forEach((powerUp) => clearTile(powerUp.col, powerUp.row));
  }, [powerUpsToClear]);

  function removePowerUp(powerUpToRemove: PowerUp) {
    clearTile(powerUpToRemove.col * 64, powerUpToRemove.row * 64);
    setPowerUps(
      // Keep all PowerUps that are not on the position of the PowerUp that is to be removed.
      powerUps.filter(
        (pUp) =>
          !(pUp.col === powerUpToRemove.col && pUp.row === powerUpToRemove.row)
      )
    );
  }

  function explosion(bombData: PlayerType) {
    // Update the players Map with the new amount of placed bombs.
    // @ts-ignore
    global.players.get(bombData.id)!.placedBombsAmount -= 1;
    // @ts-ignore
    setPlayers(global.players);

    const currentBomb = getBombForPlayer(bombData);
    playSound(SOUND_EFFECTS.EXPLOSION);
    checkBomb(currentBomb, bombData);
  }

  function checkWinCondition(alivePlayers: PlayerType[]) {
    switch (alivePlayers.length) {
      case 1:
        showWinningBox(`Player ${alivePlayers[0].id} has won the Game!`);
        break;
      case 0:
        showWinningBox("Draw!");
        break;
    }
  }

  function checkBomb(currentBomb: BombType, bombData: PlayerType) {
    try{
    drawExplosion(currentBomb);
    checkPlayerInExplosion(currentBomb);
    removeBombFromList(bombData.col, bombData.row);
    checkPowerUpInExplosion(currentBomb);
    checkOtherBombsInRange(currentBomb);
    destroyBlocks(currentBomb, defaultMap);}
    catch (e) {
      console.log(e)
    }

    // This has to be done with an intermediate value instead of the state. Otherwise, multiple explosions
    // (since they expand, this is often the case) would have race conditions due to concurrent setState (which
    // is triggered by setPowerUps, which is a custom Hook Wrapper around a state/context).
    setTimeout(() => {
      setPowerUps([...powerUpsRef.current, ...intermediatePowerUps]);
    }, 310);
    setTimeout(resetIntermediatePowerUps, 500);
  }

  function destroyBlocks(bomb: BombType, mapData: FieldWrapper[]) {
    bomb.explosionsOnFields.forEach((explosionField) => {
      const foundField = mapData.find(
        (field) =>
          field.x === explosionField.col && field.y === explosionField.row
      );

      if (
        foundField !== undefined &&
        foundField.fieldtype === FIELDS.DESTROYABLE
      ) {
        updateField(explosionField.col, explosionField.row);
        // In Online Multiplayer, only the client that placed the Bomb should calculate PowerUps.
        if (
          (user.isOnlineMultiplayer && user.multiplayerId === bomb.player.id) ||
          !user.isOnlineMultiplayer
        ) {
          setTimeout(
            () =>
              dropPowerUp(
                explosionField.col,
                explosionField.row,
                getRandomPowerUp()
              ),
            300
          );
        }
      }
    });
  }

  function resetIntermediatePowerUps() {
    intermediatePowerUps = [];
  }

  function checkPlayerInExplosion(
    bomb: BombType
  ) {
    let alivePlayers = getAlivePlayers();
    alivePlayers.forEach((player: PlayerType) => {
      const playerInExplosion =
        bomb.explosionsOnFields.find(
          (field) => field.col === player.col && field.row === player.row
        ) !== undefined;
      if (playerInExplosion) {
        // @ts-ignore
        global.players = new Map(
          // @ts-ignore
          global.players.set(player.id, {
            ...player,
            alive: false,
            ai: false,
          })
        );
        // @ts-ignore
        setPlayers(global.players);

        alivePlayers = alivePlayers.filter(
          (alivePlayer) => alivePlayer.id !== player.id
        );
      }
    });
    checkWinCondition(alivePlayers);
  }

  function getAlivePlayers(): PlayerType[] {
    //@ts-ignore
    return Array.from(global.players.values()).filter((player) => player.alive);
  }

  function checkPowerUpInExplosion(bomb: BombType) {
    powerUps.forEach((powerUp) => {
      const foundField =
        bomb.explosionsOnFields.find(
          (field) => field.col === powerUp.col && field.row === powerUp.row
        ) !== undefined;
      if (foundField) {
        removePowerUp(powerUp);
      }
    });
  }

  function removeBombFromList(col: number, row: number) {
    setBombs(
      bombsRef.current.filter(
        (bomb: BombType) => bomb.player.col !== col || bomb.player.row !== row
      )
    );
  }

  function getBombForPlayer(player: PlayerType) {
    return bombsRef.current.filter((bomb: BombType) => {
      return bomb.player.col === player.col || bomb.player.row === player.row;
    })[0];
  }

  function checkOtherBombsInRange(currentBomb: BombType) {
    bombsRef.current.forEach((bomb: BombType) => {
      const foundField =
        currentBomb.explosionsOnFields.find(
          (field) =>
            bomb.player.col === field.col && bomb.player.row === field.row
        ) !== undefined;

      if (foundField) {
        clearTimeout(bomb.explosionTimeout);
        explosion(bomb.player);
      }
    });
  }

  function drawBomb(col: number, row: number) {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;

    const x = col * blockSize;
    const y = row * blockSize;

    context?.drawImage(
      spriteSheet,
      128,
      592,
      blockSize,
      blockSize,
      x,
      y,
      blockSize,
      blockSize
    );
  }

  function drawExplosion(bomb: BombType) {
    for (let i = 0; i < bomb.explosionsOnFields.length; i++) {
      const field = bomb.explosionsOnFields[i];

      const direction = field.end
        ? "end_" + field.direction.direction
        : field.direction.direction;

      let x_base = field.col * blockSize;
      let y_base = field.row * blockSize;

      switch (direction) {
        case "none":
          context?.drawImage(
            spriteSheet,
            32,
            512,
            16,
            16,
            x_base,
            y_base,
            64,
            64
          );
          break;
        case "up":
        case "down":
          context?.drawImage(
            spriteSheet,
            48,
            512,
            16,
            16,
            x_base,
            y_base,
            64,
            64
          );
          break;
        case "left":
        case "right":
          context?.drawImage(
            spriteSheet,
            16,
            512,
            16,
            16,
            x_base,
            y_base,
            64,
            64
          );
          break;
        case "end_up":
          context?.drawImage(
            spriteSheet,
            64,
            512,
            16,
            14,
            x_base,
            y_base,
            64,
            64
          );
          break;
        case "end_down":
          context?.drawImage(
            spriteSheet,
            80,
            512,
            16,
            16,
            x_base,
            y_base,
            64,
            64
          );
          break;
        case "end_left":
          context?.drawImage(
            spriteSheet,
            0,
            512,
            16,
            16,
            x_base,
            y_base,
            64,
            64
          );
          break;
        case "end_right":
          context?.drawImage(
            spriteSheet,
            96,
            512,
            16,
            16,
            x_base,
            y_base,
            64,
            64
          );
          break;
      }
      setTimeout(clearField, 300, x_base, y_base);
    }
  }

  function clearTile(col: number, row: number) {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;

    context?.clearRect(col * blockSize, row * blockSize, 64, 64);
  }

  function clearField(col: number, row: number) {
    context = canvasRef.current ? canvasRef.current?.getContext("2d") : null;

    context?.clearRect(col, row, 64, 64);
  }

  function dropPowerUp(col: number, row: number, powerUp: PowerUpTypes) {
    if (powerUp !== PowerUpTypes.None) {
      if (user.isOnlineMultiplayer) {
        socket.emit("multiplayerAction", {
          type: "PLACE_POWER_UP",
          powerUp: powerUp,
          col: col,
          row: row,
        });
      }
      intermediatePowerUps.push({
        type: powerUp,
        col: col,
        row: row,
      });
      drawPowerUp(col, row, powerUp);
    }
  }

  function drawPowerUp(col: number, row: number, powerUp: PowerUpTypes) {
    col *= 64;
    row *= 64;
    switch (powerUp) {
      case PowerUpTypes.explosionSize:
        context?.drawImage(
          spriteSheet,
          192,
          528,
          32,
          32,
          col + 12,
          row + 12,
          40,
          40
        );
        break;
      case PowerUpTypes.numberOfMaxBombs:
        context?.drawImage(spriteSheet, 192, 592, 64, 64, col, row, 64, 64);
        break;
    }
  }

  function getRandomPowerUp(): PowerUpTypes {
    const randomValue = Math.random();
    let powerUp = PowerUpTypes.None;
    for (let i = 0; i < probabilityBordersRef.current.length; i++) {
      if (randomValue < probabilityBordersRef.current[i]) {
        powerUp = PowerUpTypes[powerUpKeys[i]];
        break;
      }
    }

    return powerUp;
  }

  function resetGame() {
    document.getElementById("winBox")!.style.visibility = "hidden";
    resetFields();
    resetPlayers();
    navigate("/");
  }

  return (
    <>
      <canvas
        height={"832px"}
        width={"960px"}
        ref={canvasRef}
        style={{ position: "absolute", top: 0, left: 0 }}
        data-testid={"bombs-renderer"}
      />
      <div
        id={"winBox"}
        className="d-grid position-absolute top-50 start-50 translate-middle glass-menu"
        style={{
          width: "300px",
          height: "auto",
          visibility: "hidden",
        }}
      >
        <h3 id={"winText"} style={{ textAlign: "center" }}>
          You Win!!!
        </h3>
        <Button
          onClick={() => resetGame()}
          text="Back to Menu"
          className={"mx-auto mb2"}
          style={{ transform: "scale(1.05) !important" }}
        />
      </div>
    </>
  );
}

export default forwardRef(BombsRenderer);
