import Game from "../../game/Game";
import KeyHandlerMultiplayer from "./KeyHandlerMultiplayer";
import AiHandler from "../../game/ai/AiHandler";

export default function LocalMultiplayer() {
  return (
    <>
      <KeyHandlerMultiplayer />
      <AiHandler aiPlayers={[3, 4]} online={false} />
      <Game />
    </>
  );
}
