import { ChangeEvent, useState } from "react";
import { Button } from "../common/Button";
import { socket } from "../App";
import { BackgroundImage } from "../common/BackgroundImage";
import { useUser } from "../account/UserContext";
import { useNavigate } from "react-router-dom";

export default function Login() {
  const [userInfo, setUserInfo] = useState({
    email: "",
    password: "",
  });
  let navigate = useNavigate();

  const { user } = useUser();

  const handleChange = async (event: ChangeEvent) => {
    // @ts-ignore
    setUserInfo({ ...userInfo, [event.target.name]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    socket.emit("login", userInfo);
  };

  socket.on("login", async (result) => {
    if (result.ok) {
      // To Be defined what we want to do. For the moment, just navigate to the game to play.
      navigate("/singleplayer");

      return;
    } else {
      await alert("Hmm, something went wrong. Namely: " + result.error);
      return;
    }
  });

  return (
    <BackgroundImage image="nathan-watson">
      <div
        className="position-absolute top-50 start-50 translate-middle glass-menu"
        id="login-menu"
      >
        <h1 className={"text-center mt-3"} data-testid={"login-title"}>
          Login
        </h1>
        {user.loggedIn ? (
          <>
            <p className={"text-center"}>
              You're already logged in. Go on, play a game :)
            </p>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit}>
              <div
                className="d-grid mt-0 px-3 position-absolute"
                style={{ width: "100%" }}
              >
                <div className="mb-3">
                  <label htmlFor={"emailInput"} className={"form-label"}>
                    Email:
                  </label>
                  <input
                    type="text"
                    name="email"
                    id={"emailInput"}
                    placeholder={"name@example.com"}
                    className={"form-control"}
                    value={userInfo.email}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <div className="mb-3">
                  <label htmlFor={"passwordInput"} className={"form-label"}>
                    Password:
                  </label>
                  <input
                    type="password"
                    name="password"
                    id={"passwordInput"}
                    className={"form-control"}
                    value={userInfo.password}
                    onChange={(e) => handleChange(e)}
                  />
                </div>
                <Button
                  text={"Login"}
                  type={"submit"}
                  style={{ transform: "scale(1.05) !important" }}
                />
              </div>
            </form>
          </>
        )}
      </div>
    </BackgroundImage>
  );
}
