import { useState } from "react";
import { Button } from "../common/Button";
import { socket } from "../App";
import { BackgroundImage } from "../common/BackgroundImage";

export default function Register() {
  const [userInfo, setUserInfo] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleChange = async (event: Object) => {
    // @ts-ignore
    setUserInfo({ ...userInfo, [event.target.name!]: event.target.value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    socket.emit("register", userInfo);
  };

  socket.on("register", async (result) => {
    if (result.ok) {
      await alert(
        "Great, that worked. Now confirm your e-mail adress and you can start playing. Good luck!"
      );
      return;
    } else {
      await alert("Hmm, something went wrong. Namely: " + result.error);
      return;
    }
  });

  return (
    <BackgroundImage image="nathan-watson">
      <div
        className="position-absolute top-50 start-50 translate-middle glass-menu"
        id="home-menu"
      >
        <h1 className={"text-center mt-3"} data-testid={"title"}>
          Register
        </h1>
        <form onSubmit={handleSubmit}>
          <div
            className="d-grid mt-0 px-3 position-absolute"
            style={{ width: "100%" }}
          >
            <div className="mb-3 mt-4">
              <label htmlFor={"usernameInput"} className={"form-label"}>
                Username:
              </label>
              <input
                type="text"
                name="username"
                id={"usernameInput"}
                placeholder={"Hansjakobli"}
                className={"form-control"}
                value={userInfo.username}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={"emailInput"} className={"form-label"}>
                Email:
              </label>
              <input
                type="text"
                name="email"
                id={"emailInput"}
                placeholder={"name@example.com"}
                className={"form-control"}
                value={userInfo.email}
                onChange={handleChange}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={"passwordInput"} className={"form-label"}>
                Password:
              </label>
              <input
                type="password"
                name="password"
                id={"passwordInput"}
                className={"form-control"}
                value={userInfo.password}
                onChange={handleChange}
              />
            </div>
            <Button
              text={"Register"}
              type={"submit"}
              style={{ transform: "scale(1.05) !important" }}
            />
          </div>
        </form>
      </div>
    </BackgroundImage>
  );
}
