import useGameFacade from "../../game/useGameFacade";
import { KeyInput } from "../../game/KeyInput";

export default function KeyHandlerMultiplayer() {
  const { facade } = useGameFacade();

  let moveToCol;
  let moveToRow;

  function moveLeft(id: number) {
    // @ts-ignore
    moveToCol = global.players.get(id)!.col - 1;
    // @ts-ignore
    moveToRow = global.players.get(id)!.row;
    facade.movePlayer(id, moveToCol, moveToRow);
  }

  function moveRight(id: number) {
    // @ts-ignore
    moveToCol = global.players.get(id)!.col + 1;
    // @ts-ignore
    moveToRow = global.players.get(id)!.row;
    facade.movePlayer(id, moveToCol, moveToRow);
  }

  function moveUp(id: number) {
    // @ts-ignore
    moveToCol = global.players.get(id)!.col;
    // @ts-ignore
    moveToRow = global.players.get(id)!.row - 1;
    facade.movePlayer(id, moveToCol, moveToRow);
  }

  function moveDown(id: number) {
    // @ts-ignore
    moveToCol = global.players.get(id)!.col;
    // @ts-ignore
    moveToRow = global.players.get(id)!.row + 1;
    facade.movePlayer(id, moveToCol, moveToRow);
  }

  function placeBomb(id: number) {
    // @ts-ignore
    facade.placeBomb(global.players.get(id)!);
  }

  function handleInput(key: any) {
    switch (key.code) {
      // Player 1 (Arrows, Enter for placing Bombs)
      case "ArrowRight":
        moveRight(2);

        break;

      case "ArrowLeft":
        moveLeft(2);

        break;

      case "ArrowUp":
        moveUp(2);

        break;

      case "ArrowDown":
        moveDown(2);

        break;
      case "Enter":
        placeBomb(2);
        break;

      // Player 2 (WASD, Space for placing bombs)
      case "KeyW":
        moveUp(1);

        break;

      case "KeyA":
        moveLeft(1);

        break;
      case "KeyS":
        moveDown(1);

        break;
      case "KeyD":
        moveRight(1);

        break;

      case "Space":
        placeBomb(1);

        break;
    }
  }

  for (const key of [
    "ArrowUp",
    "ArrowDown",
    "ArrowLeft",
    "ArrowRight",
    "Enter",
    "KeyW",
    "KeyA",
    "KeyS",
    "KeyD",
    "Space",
  ]) {
    KeyInput(key, handleInput);
  }

  return <></>;
}
