import { useState } from "react";

export function SoundEffectsSettings() {
  let playSoundPreference = localStorage.getItem("playSoundEffects");
  // Only play the effects when the preference is set to true or the player visits the site for the first time (that's wenn it's null).
  let shouldPlaySounds =
    playSoundPreference === "true" || playSoundPreference === null;

  const [playSoundEffects, setPlaySoundEffects] =
    useState<boolean>(shouldPlaySounds);

  function changeSoundEffectsPreference() {
    localStorage.setItem("playSoundEffects", (!playSoundEffects).toString());
    setPlaySoundEffects(!playSoundEffects);
  }

  return (
    <div>
      <label htmlFor={"music-select"} className={"d-block"}>
        Play Sound Effects:
      </label>
      <div>
        <input
          type={"checkbox"}
          checked={playSoundEffects}
          onChange={changeSoundEffectsPreference}
        />
      </div>
    </div>
  );
}
