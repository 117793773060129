import React from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";

import Home from "./home/Home";
import Lobby from "./lobby/Lobby";
import Login from "./login/Login";
import Register from "./register/Register";

import ChatWrapper from "./chat/ChatWrapper";

import { io } from "socket.io-client";
import ChatProvider from "./chat/ChatContext";
import MusicProvider from "./sounds/MusicContext";

import MusicPlayer from "./sounds/MusicPlayer";
import Settings from "./settings/Settings";
import UserProvider from "./account/UserContext";
import FieldsProvider from "./game/FieldsContext";
import PlayerProvider from "./game/PlayerContext";
import PowerUpProvider from "./game/PowerUpContext";
import BombsProvider from "./game/BombsContext";
import LocalMultiplayer from "./multiplayer/local/LocalMultiplayer";
import SinglePlayer from "./singleplayer/Singleplayer";
import OnlineMultiplayer from "./multiplayer/online/OnlineMultiplayer";
import HomeButton from "./home/HomeButton";

export const socket = io();

// This flag is needed so that we can prevent any further action with the players, fields or bombs-data after
// leaving the game (by pressing the Home-Button). Else, stuff is not reset properly upon reentering the game.
// @ts-ignore
global.isInGame = false;

export default function App() {
  return (
    <main>
      <UserProvider>
        <MusicProvider>
          <MusicPlayer />
          <Settings />
        </MusicProvider>
        <FieldsProvider>
          <PlayerProvider>
            <PowerUpProvider>
              <BombsProvider>
                <HomeButton />
                <Routes>
                  <Route path="/*" element={<Home />} />
                  <Route path="/" element={<Home />} />
                  <Route path="/singleplayer" element={<SinglePlayer />} />
                  <Route
                    path="/multiplayer/local"
                    element={<LocalMultiplayer />}
                  />
                  <Route path="/multiplayer/lobby" element={<Lobby />} />
                  <Route
                    path="/multiplayer/online"
                    element={<OnlineMultiplayer />}
                  />
                  <Route path="/login" element={<Login />} />
                  <Route path="/register" element={<Register />} />
                </Routes>
              </BombsProvider>
            </PowerUpProvider>
          </PlayerProvider>
        </FieldsProvider>
        <ChatProvider>
          <ChatWrapper />
        </ChatProvider>
      </UserProvider>
    </main>
  );
}
