import React, { useEffect } from "react";
import { useMusic } from "./MusicContext";

let audio = new Audio("/music/RAMMSTEIN__DEUTSCHLAND.mp3");

export default function MusicPlayer() {
  const { currentSong, isPlaying, setIsPlaying } = useMusic();

  useEffect(() => {
    audio.src = "/music/" + currentSong + ".mp3";
    audio.volume = 0.7;
    audio.loop = true;
    audio.muted = true;
    audio.play().then(() => {
      audio.muted = false;
      setIsPlaying(true);
    });
  }, [currentSong]);

  useEffect(() => {
    audio.muted = true;
    isPlaying ? audio.play().then(() => (audio.muted = false)) : audio.pause();
  }, [isPlaying]);

  // Get some sound as we start :)
  useEffect(() => {
    audio.src = "/music/RAMMSTEIN__DEUTSCHLAND.mp3";
    audio.volume = 0.7;
    audio.loop = true;
    audio.muted = true;
    audio.play().then(() => (audio.muted = false));
  }, []);

  return <></>;
}
