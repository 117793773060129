import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useEffect, useState } from "react";

export function WeatherWidget() {
  const [weatherData, setWeatherData] = useState({
    name: "Test",
    wind: { speed: 1.5 },
    main: { temp: 282.55, humidity: 80, pressure: 1023 },
    weather: [{ description: "clear sky", icon: "01d" }],
  });
  let today = new Date();
  const [time, setTime] = useState(
    addZero(today.getHours()) + ":" + addZero(today.getMinutes())
  );
  const [hasError, setError] = useState(false);

  function addZero(value: any) {
    if (value < 10) {
      value = "0" + value;
    }
    return value;
  }

  function fetchWeather() {
    fetch(
      `https://api.openweathermap.org/data/2.5/weather?lat=${process.env.REACT_APP_LAT}&lon=${process.env.REACT_APP_LON}&appid=${process.env.REACT_APP_API_KEY}`
    )
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // In case something is not quite the way it should be (e.g. invalid API-Key), throw an Error
          // since it is handled like a network Error for example.
          throw new Error("Response ist not okay.");
        }
      })
      .then((data) => {
        // @ts-ignore
        setWeatherData(data);
        setTime(addZero(today.getHours()) + ":" + addZero(today.getMinutes()));
        setError(false);
      })
      .catch((error) => {
        console.error(error);
        setError(true);
      });
  }

  useEffect(() => {
    fetchWeather();
  }, []);

  return (
    <div
      className="position-absolute bottom-0 start-0 glass-menu"
      style={{ height: "21em" }}
    >
      <div className="card-body p-3">
        {hasError ? (
          <div className="d-flex flex-column" style={{ height: "19em" }}>
            <div className="d-flex justify-content-end">
              <i
                className="bi bi-arrow-clockwise align-content-center"
                onClick={fetchWeather}
                style={{ cursor: "pointer" }}
              />
            </div>

            <div className="d-flex flex-column flex-center m-4 flex-grow-1 align-items-center justify-content-center">
              <span>
                <i className="bi-exclamation-triangle" /> Something went
                wrong...
              </span>
            </div>
          </div>
        ) : (
          <>
            <div className="d-flex">
              <h3 className="flex-grow-1">{weatherData.name}</h3>
              <h3>
                {time}{" "}
                <i
                  className="bi bi-arrow-clockwise align-content-center"
                  onClick={fetchWeather}
                  style={{ cursor: "pointer" }}
                />
              </h3>
            </div>

            <div className="d-flex flex-column text-center mt-5 mb-4">
              <h6 className="display-4 mb-0 font-weight-bold">
                {" "}
                {(weatherData.main.temp - 273.15).toFixed(0)}°C
              </h6>
              <span className="small">
                {weatherData.weather[0].description}
              </span>
            </div>

            <div className="d-flex align-items-center">
              <div className="flex-grow-1" style={{ fontSize: "1.2rem" }}>
                <div>
                  <i className="bi bi-wind" />
                  <span className="ms-1">
                    Wind speed: {(weatherData.wind.speed * 3.6).toFixed(2)}km/h
                  </span>
                </div>
                <div>
                  <i className="bi bi-moisture" />
                  <span className="ms-1">
                    Humidity: {weatherData.main.humidity}%
                  </span>
                </div>
                <div>
                  <i className="bi bi-speedometer2" />
                  <span className="ms-1">
                    Pressure: {weatherData.main.pressure}hPa
                  </span>
                </div>
              </div>
              <div>
                <img
                  src={`https://openweathermap.org/img/wn/${weatherData.weather[0].icon}@2x.png`}
                  width="100px"
                  alt={weatherData.weather[0].description}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
