import { createContext, useContext, useState } from "react";
import { BombType } from "./BombsRenderer";

type PowerUpContextHookType = {
  bombs: BombType[];
  setBombs: Function;
  bombsToDraw: BombType[];
  setBombsToDraw: Function;
  resetBombs: Function;
};

export const BombsContext = createContext<PowerUpContextHookType>({
  bombs: [],
  setBombs: () => {},
  bombsToDraw: [],
  setBombsToDraw: () => {},
  resetBombs: () => {},
});

// @ts-ignore
export default function BombsProvider({ children }) {
  const [bombs, setBombs] = useState<BombType[]>([]);
  const [bombsToDraw, setBombsToDraw] = useState<BombType[]>([]);

  function resetBombs() {
    setBombs([]);
    setBombsToDraw([]);
  }

  return (
    <BombsContext.Provider
      value={{ bombs, setBombs, bombsToDraw, setBombsToDraw, resetBombs }}
    >
      {children}
    </BombsContext.Provider>
  );
}

// Typing the custom Hook as described here: https://devtrium.com/posts/react-typescript-how-to-type-hooks#bonus-how-to-type-custom-hooks
export const useBombs = (): PowerUpContextHookType => {
  return useContext(BombsContext);
};
