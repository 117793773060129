import { createContext, useContext, useState } from "react";
import _ from "lodash";

export type PlayerType = {
  id: number;
  row: number;
  col: number;
  ai: boolean;
  numberOfMaxBombs: number;
  placedBombsAmount: number;
  explosionSize: number;
  alive: boolean;
  spriteX: number;
  spriteY: number;
  isMoving: boolean;
  currentAnimationIndex: number;
  image: HTMLImageElement;
};

type PlayerContextHookType = {
  players: Map<number, PlayerType>;
  setPlayers: Function;
  previousPlayers: Map<number, PlayerType>;
  setPreviousPlayers: Function;
  resetPlayers: Function;
};
const defaultPlayerData: Map<number, PlayerType> = new Map<number, PlayerType>([
  [
    1,
    {
      id: 1,
      row: 1,
      col: 1,
      ai: false,
      numberOfMaxBombs: 1,
      placedBombsAmount: 0,
      explosionSize: 2,
      alive: true,
      spriteX: 0,
      spriteY: 2,
      isMoving: false,
      currentAnimationIndex: 0,
      image: new Image(),
    },
  ],
  [
    2,
    {
      id: 2,
      row: 1,
      col: 13,
      ai: true,
      numberOfMaxBombs: 1,
      placedBombsAmount: 0,
      explosionSize: 2,
      alive: true,
      spriteX: 0,
      spriteY: 2,
      isMoving: false,
      currentAnimationIndex: 0,
      image: new Image(),
    },
  ],
  [
    3,
    {
      id: 3,
      row: 11,
      col: 1,
      ai: true,
      numberOfMaxBombs: 1,
      placedBombsAmount: 0,
      explosionSize: 2,
      alive: true,
      spriteX: 0,
      spriteY: 2,
      isMoving: false,
      currentAnimationIndex: 0,
      image: new Image(),
    },
  ],
  [
    4,
    {
      id: 4,
      row: 11,
      col: 13,
      ai: true,
      numberOfMaxBombs: 1,
      placedBombsAmount: 0,
      explosionSize: 2,
      alive: true,
      spriteX: 0,
      spriteY: 2,
      isMoving: false,
      currentAnimationIndex: 0,
      image: new Image(),
    },
  ],
]);
const data = _.cloneDeep(defaultPlayerData);
// Setup the 4 players on their respective starting positions and with default values.
const defaultPlayers = {
  players: data,
  setPlayers: () => {},
};

export const PlayerContext = createContext<PlayerContextHookType>({
  players: defaultPlayers.players,
  setPlayers: () => {},
  previousPlayers: defaultPlayers.players,
  setPreviousPlayers: () => {},
  resetPlayers: () => {},
});

// @ts-ignore
export default function PlayerProvider({ children }) {
  const [players, setPlayers] = useState<Map<number, PlayerType>>(
    defaultPlayers.players
  );

  function resetPlayers() {
    setPlayers(_.cloneDeep(defaultPlayerData));
    setPreviousPlayers(_.cloneDeep(defaultPlayerData));
    // @ts-ignore
    global.players = _.cloneDeep(defaultPlayerData);
  }

  const [previousPlayers, setPreviousPlayers] = useState<
    Map<number, PlayerType>
  >(defaultPlayers.players);

  return (
    <PlayerContext.Provider
      value={{
        players,
        setPlayers,
        previousPlayers,
        setPreviousPlayers,
        resetPlayers,
      }}
    >
      {children}
    </PlayerContext.Provider>
  );
}

// Typing the custom Hook as described here: https://devtrium.com/posts/react-typescript-how-to-type-hooks#bonus-how-to-type-custom-hooks
export const usePlayers = (): PlayerContextHookType => {
  return useContext(PlayerContext);
};
