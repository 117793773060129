import React, { useState } from "react";
import MusicSettings from "./MusicSettings";
import { SoundEffectsSettings } from "./SoundEffectsSettings";
import Credits from "./Credits";

export default function Settings() {
  const [menuOpen, setMenuOpen] = useState(false);

  if (menuOpen) {
    return (
      <div
        className={"position-absolute top-0 end-0 glass-menu"}
        style={{ maxWidth: "300px" }}
      >
        <i
          className="bi bi-x align-content-center position-absolute top-0 end-0 me-2 d-block"
          onClick={() => setMenuOpen(false)}
          style={{ cursor: "pointer", fontSize: "1.5em" }}
        />
        <div className={"m-4"}>
          <h2>Settings</h2>
          <hr />
          <h3>
            <i className={"bi-music-note me-1"} />
            Sounds
          </h3>
          <MusicSettings />
          <SoundEffectsSettings />
          <hr />
          <Credits />
        </div>
      </div>
    );
  }

  return (
    <div className={"position-absolute top-0 end-0 glass-menu"}>
      <span className={"mx-2"} onClick={() => setMenuOpen(true)}>
        <i className="bi-gear-fill align-middle mx-2" />
        Settings
      </span>
    </div>
  );
}
