import React from "react";
import { useNavigate } from "react-router-dom";
import { usePlayers } from "../game/PlayerContext";
import { useFields } from "../game/FieldsContext";
import { usePowerUps } from "../game/PowerUpContext";
import { useBombs } from "../game/BombsContext";
import { stopSound } from "../utils/playSound";

export default function HomeButton() {
  const navigate = useNavigate();
  const { resetPlayers } = usePlayers();
  const { resetFields } = useFields();
  const { resetPowerUps } = usePowerUps();
  const { resetBombs } = useBombs();
  function resetAndGoHome() {
    // @ts-ignore
    global.isInGame = false;
    resetFields();
    resetPlayers();
    resetBombs();
    resetPowerUps();
    stopSound();

    // Because we have to use react.. 🙃
    setTimeout(() => navigate("/"), 300);
  }

  return (
    <>
      <div
        className={"position-absolute top-0 start-0 glass-menu px-2"}
        style={{ cursor: "pointer" }}
        onClick={() => resetAndGoHome()}
      >
        <i className={"bi-house-fill me-2"} />
        Home
      </div>
    </>
  );
}
