import { FieldWrapper } from "../FieldWrapper";
import { FIELDS } from "../Fields";
import { BombType } from "../BombsRenderer";

export type location = { x: number; y: number };
export type move = "ArrowUp" | "ArrowDown" | "ArrowLeft" | "ArrowRight";

export class AiPathfinding {
  parent?: AiPathfinding;
  location: location;
  action?: move;
  h: number;
  g: number;
  f: number;

  constructor(
    location: location,
    parent?: AiPathfinding,
    action?: move,
    h = 0
  ) {
    this.parent = parent;
    this.location = location;
    this.action = action;
    this.h = h;
    this.g = 0;
    this.f = 0;
  }

  get_path(node: AiPathfinding) {
    let path = [];

    while (node.parent) {
      path.push(node);
      node = node.parent;
    }

    return path;
  }

  get_path_actions(path?: AiPathfinding[]) {
    let actions: location[] = [];
    if (path) {
      path.forEach((node) => {
        if (node.location) {
          actions.push(node.location);
        }
      });
    }
    return actions;
  }

  get_free_neighbors(game_state: FieldWrapper[], location: location) {
    let neighbors: any = this.getNeighbors(location);
    let free_neighbors = [];
    for (const key in neighbors) {
      let position = neighbors[key];
      if (this.is_in_bounds(position)) {
        if (this.is_free(game_state, position)) {
          const cell =
            game_state.find(
              (cell) => cell.x === position.x && cell.y === position.y
            ) ?? game_state[0];
          if (cell.fieldtype === FIELDS.DESTROYABLE) {
            free_neighbors.push({
              location: position,
              move: key as move,
              box: true,
            });
          } else {
            free_neighbors.push({
              location: position,
              move: key as move,
              box: false,
            });
          }
        }
      }
    }

    return free_neighbors;
  }

  getNeighbors(position: location) {
    const x = position.x;
    const y = position.y;
    return {
      ArrowLeft: { x: x - 1, y: y },
      ArrowRight: { x: x + 1, y: y },
      ArrowUp: { x: x, y: y - 1 },
      ArrowDown: { x: x, y: y + 1 },
    };
  }

  is_free(cells: FieldWrapper[], position: location) {
    const cell: FieldWrapper =
      cells.find((cell) => cell.x === position.x && cell.y === position.y) ??
      cells[0];

    return !(cell.fieldtype === FIELDS.UNDESTROYABLE);
  }

  is_in_bounds(position: location, maxX = 14, maxY = 12) {
    const xMax = maxX;
    const yMax = maxY;
    const xMin = 0;
    const yMin = 0;
    const x = position.x;
    const y = position.y;

    return x < xMax && x >= xMin && y < yMax && y >= yMin;
  }

  calculateOptimalAiPath(
    game_state: FieldWrapper[],
    start: location,
    target: location,
    bombs: BombType[]
  ) {
    let path = [];

    // add starting node to open list
    let open_list = [new AiPathfinding(start, undefined, undefined)];
    let closed_list: AiPathfinding[] = [];

    // exit the loop early if no path can be found
    // (the target is likely blocked off)
    const max_loops = 3000;
    let counter = 0;

    // while lowest rank in OPEN is not the GOAL:
    while (open_list.length > 0 && counter <= max_loops) {
      let curr_node = open_list[0];
      let curr_index = 0;

      open_list.forEach((node: AiPathfinding, index: number) => {
        if (node.f < curr_node.f) {
          curr_node = node;
          curr_index = index;
        }
      });

      // check if this node is the goal
      if (JSON.stringify(curr_node.location) === JSON.stringify(target)) {
        path = this.get_path(curr_node);
        return path;
      }

      open_list = open_list.filter((item, index) => index !== curr_index);
      closed_list.push(curr_node);

      // get neighbors of current
      let neighbors = this.get_free_neighbors(game_state, curr_node.location);
      let neighbor_nodes: AiPathfinding[] = [];
      neighbors.forEach((neighbor) => {
        neighbor_nodes.push(
          new AiPathfinding(
            neighbor.location,
            undefined,
            neighbor.move,
            getHeuristicForPosition(neighbor, bombs)
          )
        );
      });

      function getHeuristicForPosition(neighbor: any, bombs: BombType[]) {
        let positionInExplosionRange = false;
        for (let i = 0; i < bombs.length; i++) {
          const bomb = bombs[i];
          const foundField = bomb.explosionsOnFields.find(
            (field) =>
              field.col === neighbor.location.x &&
              field.row === neighbor.location.y
          );
          if (foundField !== undefined) {
            positionInExplosionRange = true;
            break;
          }
        }

        return positionInExplosionRange ? 9000 : neighbor.box ? 5 : 0;
      }

      function manhattan_distance(start: location, end: location) {
        return Math.abs(start.x - end.x) + Math.abs(start.y - end.y);
      }

      neighbor_nodes.forEach((neighbor) => {
        let in_closed = false;
        let in_open = false;

        let cost = curr_node.g + 1;

        open_list.forEach((node, index) => {
          if (
            JSON.stringify(neighbor.location) ===
              JSON.stringify(node.location) &&
            cost < neighbor.g
          ) {
            open_list = open_list.filter((item, i) => i !== index);
            in_open = true;
          }
        });

        closed_list.forEach((node, index) => {
          if (
            JSON.stringify(neighbor.location) ===
              JSON.stringify(node.location) &&
            cost < neighbor.g
          ) {
            open_list = open_list.filter((item, i) => i !== index);
            in_closed = true;
          }
        });

        if (!in_open && !in_closed) {
          neighbor.g = cost;
          open_list.push(neighbor);
          neighbor.h = manhattan_distance(neighbor.location, target);
          neighbor.f = neighbor.g + neighbor.h;
          neighbor.parent = curr_node;
        }
      });
      counter += 1;
      if (counter > max_loops) {
        path = this.get_path(curr_node);
        return path;
      }
    }
  }
}
